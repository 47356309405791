import { useEffect, useState } from "react"

import { safeJSONParse } from "~/utils/safe-json-parse"

export default function useIsE2E() {
  const [isE2E, setIsE2E] = useState(false)

  useEffect(() => {
    if (typeof window === "undefined") return

    const localE2E = localStorage.getItem("is_e2e")
    setIsE2E(safeJSONParse(localE2E) ?? false)
  }, [])

  return isE2E
}
