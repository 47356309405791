import { useEffect, useRef, useState, type ChangeEvent, type ComponentProps, type MutableRefObject } from "react"
import clsx from "clsx"
import { useFormContext, type FieldError, type FieldErrorsImpl, type Merge } from "react-hook-form"

import type { ExplicitAny } from "~/@types/generics"
import { ColorSelector } from "~/components/ui/ColorSelector"
import Icon from "~/components/abstracts/Icon"
import objectFilter from "~/utils/object-filter"

import type { theme } from "~/styles/theme.css"

import * as css from "./styles.css"

export interface Option<TValue extends string | number> {
  label: string
  value: TValue
  disabled?: boolean
  selected?: boolean
  hexa?: string
}

export interface SelectProps<TValue extends string | number> extends ComponentProps<"select"> {
  className?: string
  theme?: keyof typeof theme.colors
  selectClassName?: string
  optionClassName?: string
  iconClassName?: string
  iconColor?: keyof typeof theme.colors
  currentValue?: TValue
  forwardRef?: MutableRefObject<HTMLSelectElement | null>
  options?: Option<TValue>[]
  prefixLabel?: string
  addRequiredIndicatorOnLabel?: boolean
  errorClassname?: string
  withError?: boolean
  label?: string
  onError?: (node: FieldError | Merge<FieldError, FieldErrorsImpl<Record<string, ExplicitAny>>>) => void
  state?: NonNullable<css.SelectVariants>["state"]
}

function Select<TValue extends string | number>({
  className,
  theme = "black",
  selectClassName,
  optionClassName,
  iconClassName,
  addRequiredIndicatorOnLabel = false,
  currentValue,
  options,
  forwardRef,
  placeholder,
  autoFocus,
  onChange,
  prefixLabel,
  required,
  name,
  errorClassname,
  withError,
  onError,
  label,
  state,
  ...selectProps
}: SelectProps<TValue>) {
  const { register, formState } = useFormContext() || {}

  const error = name ? formState?.errors?.[name] : null
  const selectRef = useRef<HTMLSelectElement | null>(null)

  const placeHolder = placeholder ? placeholder + (addRequiredIndicatorOnLabel && required ? " *" : "") : null

  const findIndexFromValue = (value: string) => {
    return options?.findIndex((option) => option.value.toString() == value) ?? 0
  }

  const [valueIndex, setValueIndex] = useState(
    currentValue !== undefined ? findIndexFromValue(currentValue.toString()) : 0
  )

  useEffect(() => {
    setValueIndex(options?.findIndex((option) => option.selected) ?? 0)
  }, [])

  const customOnChange = (e: ChangeEvent<HTMLSelectElement>) => {
    const value = e.target.value
    setValueIndex(findIndexFromValue(value))
    onChange?.(e)
  }

  const cssError = (withError || error) && errorClassname ? errorClassname : null

  useEffect(() => {
    if (error) onError?.(error)
  }, [error, onError])

  const validations = {
    required,
  }

  const filteredValidations = objectFilter(validations, ([, val]) => val !== undefined)

  const setRef = (node: HTMLSelectElement | null) => {
    if (forwardRef) forwardRef.current = node
    if (selectRef) selectRef.current = node
  }

  const withColorSelector = Boolean(options?.[valueIndex]?.["hexa"])

  return (
    <div data-comp={"Abstracts/Select"} className={clsx(className, css.Select, cssError)}>
      {withColorSelector && <ColorSelector className={clsx(css.colorSelector)} hexa={options?.[valueIndex]?.hexa} />}
      {label && <label className={clsx(css.label)}>{label}</label>}
      <select
        ref={setRef}
        className={clsx(selectClassName, css.select({ withColorSelector, state }), cssError)}
        onChange={customOnChange}
        autoFocus={autoFocus}
        name={name}
        {...(currentValue && { value: currentValue })}
        {...selectProps}
        {...(name && register?.(name, filteredValidations))}
      >
        {placeHolder && (
          <option className={clsx(optionClassName, cssError)} value="" disabled selected>
            {placeHolder}
          </option>
        )}
        {options?.map(({ value, label, ...rest }, index: number) => {
          return (
            <option
              className={clsx(optionClassName, cssError)}
              key={`${value}-${index}-${valueIndex}`}
              value={value}
              label={`${prefixLabel && index === valueIndex ? prefixLabel : ""}${label}`}
              {...rest}
            />
          )
        })}
      </select>
      <Icon name={"Chevron"} direction="bottom" theme={theme} className={clsx(css.icon, iconClassName, cssError)} />
    </div>
  )
}

export default Select
